<template>
  <div>
    <top-header v-bind:title="$t('priceTagCreation')">
      <header-button icon="mdi-import"
                     right
                     slot="right-button"
                     v-bind:label="$t('import')"
                     v-on:click="showImportCsvPicker"
                     v-bind:disabled="loading" />
      <v-tabs background-color="transparent"
              dark
              slot="tabs"
              v-model="tab">
        <v-tab href="#default">{{ $t('default') }}</v-tab>
        <v-tab href="#sale">{{ $t('sale') }}</v-tab>
        <v-tab href="#bundles">{{ $t('bundles') }}</v-tab>
        <v-tab href="#packs">{{ $t('packs') }}</v-tab>
        <v-tab href="#single">{{ $t('single') }}</v-tab>
        <v-tab href="#alcohol">{{ $t('alcohol') }}</v-tab>
        <v-tab href="#sweets">{{ $t('sweets') }}</v-tab>
        <v-tab href="#saleSmall">{{ $t('saleSmall') }}</v-tab>
      </v-tabs>
    </top-header>
    <v-progress-linear color="accent"
                       indeterminate
                       v-if="loading" />
    <v-tabs-items class="transparent"
                  v-model="tab">
      <v-tab-item eager value="default">
        <v-container>
          <price-tag-product-table-card ref="productTable"
                                        v-bind:products="doProducts"
                                        v-bind:loading="loading"
                                        v-on:print="print" />
        </v-container>
      </v-tab-item>
      <v-tab-item eager value="sale">
        <v-container>
          <sale-product-table-card ref="saleProdcutTableCard"
                                   v-bind:sale-products="saleProducts"
                                   v-bind:loading="loading"
                                   v-on:print="printSales" />
        </v-container>
      </v-tab-item>
      <v-tab-item eager value="bundles">
        <v-container>
        <price-tag-product-table-card ref="productTable"
                                      v-bind:products="doProducts"
                                      v-bind:loading="loading"
                                      v-on:print="printSmall"
                                      print-style="bundles" />
                                    </v-container>
      </v-tab-item>
      <v-tab-item eager value="packs">
        <v-container>
        <price-tag-product-table-card ref="productTable"
                                      v-bind:products="doProducts"
                                      v-bind:loading="loading"
                                      v-on:print="printSmall"
                                      print-style="packs" />
                                    </v-container>
      </v-tab-item>
      <v-tab-item eager value="single">
        <v-container>
        <price-tag-product-table-card ref="productTable"
                                      v-bind:products="doProducts"
                                      v-bind:loading="loading"
                                      v-on:print="printSmall"
                                      print-style="single"
                                      v-on:change-count="changeCount" />
                                    </v-container>
      </v-tab-item>
      <v-tab-item eager value="alcohol">
        <v-container>
        <price-tag-product-table-card ref="productTable"
                                      v-bind:products="doProducts"
                                      v-bind:loading="loading"
                                      v-on:print="printSmall"
                                      print-style="alcohol" />
                                    </v-container>
      </v-tab-item>
      <v-tab-item eager value="sweets">
        <v-container>
        <price-tag-product-table-card ref="productTable"
                                      v-bind:products="doProducts"
                                      v-bind:loading="loading"
                                      v-on:print="printSmall"
                                      print-style="sweets" />
                                    </v-container>
      </v-tab-item>
      <v-tab-item eager value="saleSmall">
        <v-container>-
        <sale-product-table-card ref="saleProdcutTableCard"
                                 v-bind:sale-products="saleProducts"
                                 v-bind:loading="loading"
                                 v-on:print="printSmallSales"
                                 print-style="saleSmall" />
                                </v-container>
      </v-tab-item>
    </v-tabs-items>
    <input accept="text/csv"
           class="hidden"
           ref="fileInput"
           type="file"
           v-on:change="fileInputChange">
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import Papa from 'papaparse'

  import TopHeader from '@/components/common/TopHeader.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import PriceTagProductTableCard from '@/components/price-tag/PriceTagProductTableCard.vue'
  import SaleProductTableCard from '@/components/price-tag/SaleProductTableCard.vue'

  export default {
    components: {
      TopHeader,
      HeaderButton,
      PriceTagProductTableCard,
      SaleProductTableCard
    },
    computed: {
      saleProducts() {
        return this.liveSalePrices.map(s => ({
          ...s,
          key: `${s.productId}-${s.unit}-${s.start}-${s.end}`,
          count: 0,
          sale: true,
          saleText: ''
        }))
      },
      ...mapGetters({
        liveProducts: 'common/liveProducts',
        liveSalePrices: 'common/liveSalePrices',
        siteId: 'common/currentSiteId'
      })
    },
    async created() {
      this.loading = true
      await this.loadLiveProducts()
      await this.loadLiveSalePrices({ siteId: this.siteId })
      this.loading = false
    },
    data: () => ({
      doProducts: [],
      loading: false,
      tab: null
    }),
    methods: {
      changeCount({ index, value }) {
        this.doProducts[index].count = parseInt(value)
      },
      readCsv(file) {
        return new Promise((resolve, reject) => {
          Papa.parse(file, {
            complete: results => {
              resolve(results.data)
            },
            error: err => {
              reject(err)
            },
            header: true,
            skipEmptyLines: true,
            transform: (value, field) => {
              return value
            }
          })
        })
      },
      async fileInputChange(ev) {
        if (ev == null) {
          return
        }

        const file = ev.srcElement.files[0]

        if (file == null) {
          return
        }

        try {
          this.loading = true

          const reader = new FileReader()

          reader.onloadend = async () => {
            this.loading = false

            await this.importCsv(reader.result)

          }

          reader.readAsText(file)
        } catch (e) {
          this.loading = false
        }
      },
      async printSmall()
      {
        this.loading = true

        const products = this.doProducts.map(p => ({
          siteId: this.siteId,
          productId: p.productId,
          count: p.count,
          printStyle: p.printStyle
        }))


        const blob = await this.createPriceTagPdf({ productPriceTags: products })

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = url
        a.download = `Preisschilder.pdf`

        document.body.appendChild(a);

        a.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        )

        a.remove()

        this.loading = false
      },
      async printSmallSales()
      {
        this.loading = true

        const products = this.saleProducts.filter(p => p.count > 0).map(p => ({
          siteId: p.siteId,
          productId: p.productId,
          count: p.count,
          printStyle: 'smallSales',
          salePrice: p.price,
          saleUnit: p.unit,
          saleStorageFactor: p.storageFactor,
          saleCapacity: p.capacity
        }))


        const blob = await this.createPriceTagPdf({ productPriceTags: products })

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = url
        a.download = `Preisschilder.pdf`

        document.body.appendChild(a);

        a.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        )

        a.remove()

        this.loading = false
      },
      async print() {
        await this.priceTagSetProducts({ products: this.doProducts})

        console.log(this.doProducts)

        this.$router.push({ name: 'price-tag-print' })
      },
      async printSales() {
        this.loading = true

        const filteresSaleProducts = this.saleProducts.filter(p => p.count > 0)

        let doProducts = []

        for (let saleProduct of filteresSaleProducts) {
          let product = this.liveProducts.find(p => p.productId === saleProduct.productId)


          if (!product) {
            continue
          }

          await this.loadLivePrices({ siteId: this.siteId, productId: saleProduct.productId })

          product.count = saleProduct.count
          product.sale = true
          product.saleText = saleProduct.saleText
          product.salePrice = saleProduct.price
          product.saleUnit = saleProduct.unit
          product.saleQuantity = saleProduct.storageFactor

          doProducts.push(product)
        }

        await this.priceTagSetProducts({ products: doProducts})

        this.loading = false

        this.$router.push({ name: 'price-tag-print' })
      },
      showImportCsvPicker() {
        this.$refs.fileInput.click()
      },
      async importCsv(csv) {
        console.log('importCsv')

        const result = await this.readCsv(csv)

        console.log(result)

        for (let element of result) {
          console.log(element)
          let printStyle = this.tab

          if (this.tab === 'default') {
            printStyle = null
          }

          this.$refs.productTable.addProductById(element?.Artikelnummer, parseInt(element?.Anzahl, 10), printStyle)
      
        }

        this.$refs.fileInput.value = ''
      },
      ...mapActions({
        loadLiveProducts: 'common/loadLiveProducts',
        priceTagSetProducts: 'priceTag/setProducts',
        loadLiveSalePrices: 'common/loadLiveSalePrices',
        loadLivePrices: 'common/loadLivePrices',
        createPriceTagPdf: 'priceTag/createPriceTagPdf'
      })
    },
    name: 'PriceTag'
  }
</script>

<i18n>
  {
    "de": {
      "priceTagCreation": "Preisschilderstellung",
      "import": "Importieren",
      "default": "Standard",
      "sale": "Aktionen",
      "packs": "X-Pack",
      "single": "Einzel",
      "alcohol": "WSS",
      "saleSmall": "Aktionen (klein)",
      "bundles": "Gebinde",
      "sweets": "Süßwaren"
    },
    "en": {
      "priceTagCreation": "Price tag creation",
      "import": "Import",
      "default": "Default",
      "sale": "Sale",
      "packs": "Packs",
      "single": "Single",
      "alcohol": "Alcohol",
      "saleSmall": "Sale small",
      "bundles": "Bundles",
      "sweets": "Sweets"
    }
  }
</i18n>